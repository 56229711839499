@charset "utf-8";

// Dimensions

@import "./theme";

.ant-menu-inline .ant-menu-item {
    margin-top: 0 !important; /* Override the margin-top */
}
.ant-menu-vertical .ant-menu-item{
    margin-top: 0 !important; /* Override the margin-top */

}
.ant-drawer-header-close-only {
    background-color: #f0f2f5!important;
}
    .anticon svg {
        border: 1px solid black!important;
    }

//  HomePage

.home-content {
    padding: 40px 60px;
    margin-bottom: 100px;

}

/* unvisited link */
.link:link {
    color: var(--global-theme-color);
    text-decoration: none;
}

/* mouse over link */
.link:hover {
    color: var(--global-hover-color);
    text-decoration: underline;
}


.home-title {
    text-align: start;

}
.title {
    font-family: "Montserrat","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 42px;
    padding-bottom: 20px;
    font-weight: 600;
}
.description {
    font-family: 'Raleway';
    font-size: 25px;
    color: var(--global-text-color-light);
}

.section {
    font-family: "Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 24px;
    font-weight: 400;
    padding-bottom: 20px;
}
.subsection {
    font-family: "Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 22px;
}

.paragraph {
    text-align: start;
    font-family: 'Raleway';
    // width: 70%;
    margin: auto;
}

.card {
    height: 100%;
    // border-radius: 20px;
    // max-width: 350px;
    

    .card-title {
        font-weight: 400;
        text-align: center;
        font-size: 25px;
        font-family: "Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;
        width: 100%;
        white-space: pre-line;
    }
}

// PublicationsPage

.publication {
    font-family: "Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 42px;
    padding-bottom: 20px;
    font-weight: 600;
    text-align: start;
    // margin-bottom: 20px; 
}

// youtube embed
.home-video {
    margin-left: 5%;
    padding-top: 30px;
    width: 90%;
}

.publication-button {
    margin-bottom: 10px;
    margin-right: 10px;
}

.abstract {
    margin-left: 15px;
    margin-top: 10px;
    border: dashed 1px;
    padding-left: 5px;
    padding-right: 5px;

    .text {
        margin-bottom: 0;
    }
}

.hidden { display:none; }